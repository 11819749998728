import React from "react";
import { Button, Checkbox, Popover, Radio, Divider, Menu } from "antd";
import {
  valueWithCommas,
  getCategoriesIcon, 
  getCategoriesColor,
} from "../../../../../../../../utils/constants"
import Arrow from "../../../../../../../../assets/images/Inner/arrow.svg";
import CloseCheck from "../../../../../../../../assets/images/close.png";
import {firstYear, lastYear, secondYear} from "../../../../../../../../utils/constants";
import _ from "lodash";


const CategorySettings = (props) => {

  // 
  const {
    
    // spending category
    selectedCategory, 
    categoryObject,
    categoryList,
    onCategoryChange,
    
    // intro bonus toggle
    isIncludeIntroBonus,
    onIncludeIntroChange,
    
    // projection years
    yearProjection, 
    onYearProjectionChange,
    
  } = props || {};
  
  
  // 
  const [isOpen, setIsOpen] = React.useState(false);
  
  
  // projections
  const projYears = [firstYear, secondYear, lastYear]; // note that these are fns for some reason
  
  // 
  const filtersContent = (
    <div style={{width: ""}}>
    
      {/* spending categories */}
      <Menu onClick={({key}) => { setIsOpen(false); onCategoryChange(key); }} style={{borderRight: "0px solid white"}}>
          {
            (categoryList || []).filter(name => name !== "Other").map((cat) => (
                <Menu.Item key={cat}>
                  <img
                      className="mr-2"
                      src={getCategoriesIcon(cat)}
                      style={{
                        background: getCategoriesColor(cat),
                        width: "32px",
                        padding: "3px",
                        borderRadius: "5px",
                      }}
                  />
                  {`${cat}: $${valueWithCommas(categoryObject?.[cat]?.[yearProjection]?.spending || 0)}`}
                </Menu.Item>
            ))
          }
      </Menu>
    </div>
  );
  
  // 
  return (
    <div className="card-filter mb-3">
      <div className="filter">
        <span className="feature-filter">
          <div className="form-group mb-0">
            <Popover 
              content={filtersContent} 
              trigger={"click"} 
              placement="bottomLeft" 
              title={null} 
              visible={isOpen} 
              onVisibleChange={(visible) => setIsOpen(visible)}
            >
              <Button className="" block={true}>
                <div className="pl-1 pr-1" style={{}}>
                
                  {/* text */}
                  <span className="mr-2 ml-2">
                    <img
                        className="mr-1"
                        src={getCategoriesIcon(selectedCategory)}
                        style={{
                          background: getCategoriesColor(selectedCategory),
                          width: "20px",
                          padding: "3px",
                          borderRadius: "5px",
                        }}
                    />
                    {
                      `${selectedCategory}: $${valueWithCommas(categoryObject?.[selectedCategory]?.[yearProjection]?.spending || 0)}`
                    }
                  </span>
                  
                  {/* arrow */}
                  <img src={Arrow} className="arrow-tab-more"/>
                </div>
              </Button>
            </Popover>
          </div>
        </span>
      </div>
    </div>
  );
  
}

export default CategorySettings
