import React from "react"
import {connect} from "react-redux";
import { cloneDeep } from "lodash";
import {Button, Skeleton, Dropdown, Menu} from "antd";
import 'antd/lib/dropdown/style/index';
import HorizontalCardsList from "./component/HorizontalCardsList"
import Arrow from "../../../../../../../assets/images/Inner/arrow.svg";
import {
    valueWithCommas,
    getCategoriesColor,
    getCategoriesIcon,
    modifyCardsList,
    getSpendingCategoryList
} from "../../../../../../../utils/constants";
import {setResultSetting} from "../../../../../../../redux/modules/resultSetting/resultSettingActions";
import YearProjection from "../YearProjection";
import { Loading } from '../../../../../../../common/components/Loading/Loading';
import "./recommandedCards.scss"

class RecommendedCards extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            selectedCategory: (props.categoryList || [])?.[0] || "",
            isYearChanging: false,
            isCategoryChanging: false
        }
    }

    componentWillMount() {
        this.onCategoryChange((this.props.categoryList || [])?.[0] || "")
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.categoryList !== this.props.categoryList && !this.state.selectedCategory) {
            this.onCategoryChange((this.props.categoryList || [])?.[0] || "")
        }
    }

    onCategoryChange = (value) => {
        this.setState({
            selectedCategory: value,
            isCategoryChanging: true
        }, () => this.setState({isCategoryChanging: false}))
    }

    onYearProjectionChange = (value) => {
        this.setState({
            isYearChanging: true
        })
        this.props.onSetYearProjection({bottomYearProjection: value})
        setTimeout(() => {
            localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
            this.setState({isYearChanging: false})
        }, 200)
    }

    onIncludeIntroChange = () => {
        this.props.onSetYearProjection({bottomIncludedIntroBonus: !this.props.bottomIncludedIntroBonus})
        setTimeout(() => localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state)), 100)
    }

    render() {
        const { onSelectCard, categoryObject, categoryList, bottomYearProjection, cards, topCategory, bottomIncludedIntroBonus } = this.props
        const { selectedCategory, isYearChanging, isCategoryChanging } = this.state

        const filterContent = () => (
              <Menu onClick={({key}) => this.onCategoryChange(key)}>
                  {
                    (categoryList || []).filter(name => name !== "Other").map((category) => (
                        <Menu.Item key={category}>{`${category}: $${valueWithCommas(categoryObject?.[category]?.[bottomYearProjection]?.spending || 0)}`}</Menu.Item>
                    ))
                  }
              </Menu>
        )

        return(
            <div className="mt-5">
                <h3><b>Recommended cards by your spending category</b></h3>
                <p className="mb-2">Based on your spending, your top spending category is {topCategory}</p>
                <p className="view-top-cards mb-0">
                    <span className="mr-3">View top cards for</span>
                    <div className="card-filter mb-3">
                        <div className="filter">
                            <span className="feature-filter ml-0">
                              <div className="form-group mb-0">
                                  <Dropdown overlay={filterContent} trigger="click">
                                    <Button className="pl-0 pr-0">
                                      <img
                                        className="cat-image mr-1"
                                        src={getCategoriesIcon(selectedCategory)}
                                        style={{background: getCategoriesColor(selectedCategory)}}
                                      />
                                      { `${selectedCategory}: $${valueWithCommas(categoryObject?.[selectedCategory]?.[bottomYearProjection]?.spending || 0)}` }
                                      <img src={Arrow} className="ml-2 arrow-tab-more"/>
                                    </Button>
                                  </Dropdown>
                              </div>
                            </span>
                        </div>
                    </div>
                </p>

                <p className="d-flex text-nowrap mb-1">
                    Include intro bonus
                    <div className="bank-area mb-0 ml-3">
                        <div className="toggle">
                            <label className="switch">
                                <input type="checkbox" checked={bottomIncludedIntroBonus} onChange={this.onIncludeIntroChange} />
                                <span className="slider round"/>
                            </label>
                        </div>
                    </div>
                </p>

                <YearProjection
                    yearProjection={bottomYearProjection}
                    onYearProjectionChange={this.onYearProjectionChange}
                />

                {
                    isYearChanging ?
                        <div className="card-spinner"><Loading isInteral={true} isCenter={true}/></div> :
                        isCategoryChanging ?
                            null :
                        <HorizontalCardsList
                            onSelectCard={onSelectCard}
                            cardList={modifyCardsList(cloneDeep(categoryObject?.[selectedCategory]?.[bottomYearProjection]?.results || []), cloneDeep(cards))}
                            spending={categoryObject?.[selectedCategory]?.[bottomYearProjection]?.spending || ""}
                            selectedCategory={selectedCategory}
                            bottomYearProjection={bottomYearProjection}
                            bottomIncludedIntroBonus={bottomIncludedIntroBonus}
                        />
                }
            </div>
        )
    }
}


const mapStateToProps = state => ({
    state,
    categoryObject: state?.cards?.cardsDetails?.[state.resultSetting.bottomIncludedIntroBonus ? "resultsByCategory" : "noBonus_resultsByCategory"] || {},
    categoryList: getSpendingCategoryList(state?.cards?.cardsDetails?.[state.resultSetting.bottomIncludedIntroBonus ? "resultsByCategory" : "noBonus_resultsByCategory"] || {}, state.resultSetting.bottomYearProjection) || [],
    cards: state?.cards?.cardsDetails?.cards || {},
    topCategory: state?.cards?.cardsDetails?.transactions_statistics?.[0]?.category || "",
    bottomYearProjection: state.resultSetting.bottomYearProjection,
    bottomIncludedIntroBonus: state.resultSetting.bottomIncludedIntroBonus
});

const mapDispatchToProps = dispatch => {
    return {
        onSetYearProjection: (data) => {
            dispatch(setResultSetting(data))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedCards);
