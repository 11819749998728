import React from "react"
import {connect} from "react-redux";
import { cloneDeep } from "lodash";
import {Button, Skeleton, Dropdown, Menu} from "antd";
import 'antd/lib/dropdown/style/index';
import HorizontalCardsList from "./component/HorizontalCardsList"
import Arrow from "../../../../../../../assets/images/Inner/arrow.svg";
import {
    valueWithCommas,
    getCategoriesColor,
    getCategoriesIcon,
    modifyCardsList,
    getSpendingCategoryList
} from "../../../../../../../utils/constants";
import {setResultSetting} from "../../../../../../../redux/modules/resultSetting/resultSettingActions";
import YearProjection from "../YearProjection";
import { Loading } from '../../../../../../../common/components/Loading/Loading';
import CategorySettings from './component/CategorySettings';
import "./recommandedCards.scss"

class RecommendedCards extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            selectedCategory: (props.categoryList || [])?.[0] || "",
            isYearChanging: false,
            isCategoryChanging: false,
        }
    }

    componentWillMount() {
        this.onCategoryChange((this.props.categoryList || [])?.[0] || "")
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.categoryList !== this.props.categoryList && !this.state.selectedCategory) {
            this.onCategoryChange((this.props.categoryList || [])?.[0] || "")
        }
    }

    onCategoryChange = (value) => {
        this.setState({
            selectedCategory: value,
            isCategoryChanging: true
        }, () => this.setState({isCategoryChanging: false}))
    }

    // onYearProjectionChange = (value) => {
    //     this.setState({
    //         isYearChanging: true
    //     })
    //     this.props.onSetYearProjection({bottomYearProjection: value})
    //     setTimeout(() => {
    //         localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
    //         this.setState({isYearChanging: false})
    //     }, 200)
    // }

    // onIncludeIntroChange = () => {
    //     this.props.onSetYearProjection({bottomIncludedIntroBonus: !this.props.isIncludeIntroBonus})
    //     setTimeout(() => localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state)), 100)
    // }
    
    
    // 
    render() {
        
        const {
            onSelectCard,
            categoryObject,
            categoryList,
            yearProjection,
            isIncludeIntroBonus,
            cards,
            topCategory,
        } = this.props
        const { selectedCategory, isYearChanging, isCategoryChanging } = this.state

        const filterContent = () => (
              <Menu onClick={({key}) => this.onCategoryChange(key)}>
                  {
                    (categoryList || []).filter(name => name !== "Other").map((category) => (
                        <Menu.Item key={category}>{`${category}: $${valueWithCommas(categoryObject?.[category]?.[yearProjection]?.spending || 0)}`}</Menu.Item>
                    ))
                  }
              </Menu>
        )
        
        // 
        return (
            
            <div className="">
                
                {/* title */}
                <div className="mb-3" style={{fontSize: "26px", lineHeight: "26px", padding: "0px 5px"}}>
                    <b>Recommended cards by{this.props.isBenchmarkMode? "" : " your"} spending category</b>
                </div>
                
                {/* subtext */}
                {this.props.isBenchmarkMode?
                    <>
                        <p className="mb-2" style={{padding: "0px 5px"}}>
                            Based on average American spending, the top spending category is {topCategory}.<br/>
                            Get a personalized recommendation
                            {this.props.isBenchmarkMode?
                                <a href="/dashboard" className="ml-1 ga-recsFlowClick ga-recsFlowClick-section2">here</a>
                                :
                                <a href="#" className="ml-1" onClick={this.props.backToFirstStep}>here</a>
                            }!
                        </p>
                    </>
                    
                    :
                    <div className="mb-2" style={{paddingLeft: "5px", paddingRight: "5px"}}>
                        Based on your spending, your top spending category is {topCategory}
                    </div>
                }
                
                {/* dropdown filters */}
                <CategorySettings {...{
                  onDismissFilter: null,
                  isIncludeIntroBonus: isIncludeIntroBonus,
                  // onIncludeIntroChange: this.onIncludeIntroChange,
                  yearProjection: yearProjection,
                  // onYearProjectionChange: this.onYearProjectionChange,
                  selectedCategory, 
                  categoryObject,
                  categoryList,
                  onCategoryChange: this.onCategoryChange,
                }}/>
                
                {/* card list */}
                {
                    isYearChanging ?
                        <div className="card-spinner"><Loading isInteral={true} isCenter={true}/></div>
                        :
                        (
                            isCategoryChanging ? null 
                            :
                            <HorizontalCardsList
                                onSelectCard={onSelectCard}
                                cardList={modifyCardsList(cloneDeep(categoryObject?.[selectedCategory]?.[yearProjection]?.results || []), cloneDeep(cards))}
                                spending={categoryObject?.[selectedCategory]?.[yearProjection]?.spending || ""}
                                selectedCategory={selectedCategory}
                                bottomYearProjection={yearProjection}
                                bottomIncludedIntroBonus={isIncludeIntroBonus}
                            />
                        )
                }
            </div>
        )
    }
}


const mapStateToProps = state => ({
    state,
    categoryObject: state?.cards?.cardsDetails?.[state.resultSetting.topIncludedIntroBonus ? "resultsByCategory" : "noBonus_resultsByCategory"] || {},
    categoryList: getSpendingCategoryList(state?.cards?.cardsDetails?.[state.resultSetting.topIncludedIntroBonus ? "resultsByCategory" : "noBonus_resultsByCategory"] || {}, state.resultSetting.bottomYearProjection) || [],
    cards: state?.cards?.cardsDetails?.cards || {},
    topCategory: state?.cards?.cardsDetails?.transactions_statistics?.[0]?.category || "",
});

const mapDispatchToProps = dispatch => {
    return {
        onSetYearProjection: (data) => {
            dispatch(setResultSetting(data))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedCards);
