import React from "react";
import { Tooltip } from "antd";
import MediaQuery from "react-responsive";
import Help from "../../../../../../assets/images/dashboard/help-icon.svg";
import ArrowBlack from "../../../../../../assets/images/dashboard/arrow-black.svg";
import Plus from "../../../../../../assets/images/dashboard/plus.svg";
import Minus from "../../../../../../assets/images/dashboard/minus.svg";
import Equal from "../../../../../../assets/images/dashboard/equal.svg";
import Rate from "../../../../../../assets/images/dashboard/rate.svg";
import Prize from "../../../../../../assets/images/dashboard/prize.svg";
import CardDetailsByGraph from "../component/CardDetailsByGraph"
import SeeMoreDetails from "../component/SeeMoreDetails"
import {
    getCardImageURL,
    getFeaturesIcon,
    commaSeparator,
    valueWithCommas,
    getToolTipConstant,
    toFixedToDecimal,
    firstYear,
    secondYear,
    lastYear
} from "../../../../../../utils/constants"
import "../../../../../../common/styles/dashboard.scss"
import './CardDetails.scss';


class CardDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isReward: true,
            isIntroBonus: true,
            isAnnualFee: true
        }
    }

    render() {
        const { selectedCard, onCardChange, getCardsList, onGetDate, avgSpending, spending, selectedDate, topYearProjection, topIncludedIntroBonus } = this.props
        // const { cardId, money,  points, introMoney, introPoints, totalMoney, card = {}, calculation = {} } = (getCardsList() || []).length && getCardsList()[selectedCard] || {}
        const isSecondYear = topYearProjection === secondYear()
        const isLastYear = topYearProjection === lastYear()
        const {
            cardId,
            money,
            points,
            introMoney,
            introPoints,
            totalMoney,
            calculation = {},
            name = "",
            stat = [],
            link = "",
            cardInfo = "",
            features = [],
            rewardInfoLong = "",
            rewardRate = "",
            annualFee = "",
            creditRange = [],
            introOffer = "",
            introOfferGeneralDetail = [],
            moreDetailInfo = [],
            moreDetailNotes = [],
            unitForPoints = "",
            otherRewards = "",
            totalMoneyPerYear = "",
            introOfferDetail = [],
            annualFeeDisplay = [],
            annualFeeDetail = [],
            rewardRateGeneralDetail = [],
            annualFeeGeneralDetail = [],
            rewardDetail = []
        } = (getCardsList() || []).length && getCardsList()[selectedCard] || {}
        const { isReward, isIntro, isAnnual } = calculation || {}

        const cardLength = (getCardsList() || []).length
        
        
        // 
        const cardDetailsByGraphObj = {
            stat, money, avgSpending, spending, onGetDate, selectedDate, topYearProjection,
            
            rewardInfoLong, features, otherRewards, annualFeeDisplay, annualFeeGeneralDetail, 
            creditRange, rewardRate, rewardRateGeneralDetail, introOffer, introOfferGeneralDetail
        };
        
        //
        return (
            <div className="tab-card-data">
                {
                    selectedCard === 0 ?
                        <div className="top-pick">
                            <span>luci's pick</span>
                        </div> : null
                }
                <h4 className="slider-card-title">{name}</h4>
                <div className="card-first">
                    <div className="visible-xs">
                        {
                            selectedCard === 0 ? null :
                                <img
                                    src={ArrowBlack}
                                    className={`black-arrow ${selectedCard === 0 ? "cursor-not-allowed" : ""}`}
                                    onClick={() => selectedCard === 0 ? null : onCardChange(selectedCard === 0 ? cardLength - 1 : selectedCard - 1)}
                                />
                        }
                        {
                            cardLength - 1 === selectedCard ? null :
                                <img
                                    src={ArrowBlack}
                                    className={`black-arrow right ${cardLength - 1 === selectedCard ? "cursor-not-allowed" : ""}`}
                                    onClick={() => cardLength - 1 === selectedCard ? null : onCardChange(cardLength - 1 === selectedCard ? 0 : selectedCard + 1)}
                                />
                        }
                    </div>
                    <div className="t-card-img">
                        <img
                            className="card-image cursor-pointer" src={getCardImageURL(cardId)}
                            onClick={() => window.open(link, "_blank")}
                        />
                    </div>
                    <div className="t-card-name">
                        <p>{cardInfo || ''}</p>
                    </div>
                    <div className="t-card-action">
                        <button
                            className="btn luci-btn primary-btn shadow-btn font-weight-bold pt-3 pb-3 mb-3"
                            type="submit"
                            onClick={() => window.open(link, "_blank")}
                        >
                            Apply
                        </button>
                    </div>
                </div>

                <div className={`card-points card-reward-xs ${topIncludedIntroBonus ? "" : "not-included-intro-bonus"}`}>
                    <div className="card-r ml-5">
                        <span className={`rft ${!isReward ? "color-gray" : ""}`}>
                            Reward from Transactions
                         </span>
                        <div className="d-flex a-center">
                            <span className={`font-big ${!isReward ? "color-gray" : ""}`}>${valueWithCommas(money || 0)}</span>
                            { !!(rewardDetail || []).length &&
                                <Tooltip
                                    title={getToolTipConstant(rewardDetail || [])}
                                    trigger={"click"}
                                >
                                    <img className="help-icon" src={Help} style={!isReward ? {opacity: '0.5'} : null}/>
                                </Tooltip>
                            }
                        </div>
                        {
                            points ?
                                <small className={!isReward ? "color-gray" : ""}>{`(${commaSeparator(points || 0)} Points)`}</small> :
                                (introPoints || unitForPoints || isSecondYear || isLastYear) ?
                                <small className="invisible">Reward</small> : null
                        }
                    </div>

                    {
                        topIncludedIntroBonus ?
                            <>
                                <div className={`card-img-r`}>
                                    <img src={Plus} style={(!isReward || !isIntro) ? {opacity: '0.5'} : null}/>
                                </div>
                                <div className={`card-bonus`}>
                                    <span className={`rft ${!isIntro ? "color-gray" : ""}`}>
                                        Intro Bonus
                                    </span>
                                    <div className="d-flex a-center">
                                        <span className={`font-big ${!isIntro ? "color-gray" : ""}`}>${valueWithCommas(introMoney || 0)}</span>
                                        { !!(introOfferDetail || []).length &&
                                        <Tooltip
                                            title={getToolTipConstant(introOfferDetail || [])}
                                            trigger={"click"}
                                        >
                                            <img className="help-icon" src={Help} style={!isIntro ? {opacity: '0.5'} : null}/>
                                        </Tooltip>
                                        }
                                    </div>
                                    { (introPoints || unitForPoints) ?
                                        <small className={!isIntro ? "color-gray" : ""}>
                                            {`(${commaSeparator(introPoints || 0)} ${unitForPoints})`}
                                        </small> :
                                        (points || isSecondYear || isLastYear) ?
                                            <small className="invisible">Intro Bonus</small> : null
                                    }
                                </div>
                            </> : null
                    }

                    <div className="card-img-r">
                        <img src={Minus} style={!isAnnual ? {opacity: '0.5'} : null}/>
                    </div>
                    <div className="card-fee">
                        <span className={`rft ${!isAnnual ? "color-gray" : ""}`}>
                            Annual Fee
                        </span>
                        <div className="d-flex a-center">
                            <span className={`font-big ${!isAnnual ? "color-gray" : ""}`}>${annualFee}</span>
                            { !!(annualFeeDetail || []).length &&
                                <Tooltip
                                    title={getToolTipConstant(annualFeeDetail || [])}
                                    trigger={"click"}
                                >
                                    <img className="help-icon" src={Help} style={!isAnnual ? {opacity: '0.5'} : null}/>
                                </Tooltip>
                            }
                        </div>
                        { (introPoints || unitForPoints || points || isSecondYear || isLastYear) ? <small className="invisible">Annual Fee</small> : null }
                    </div>
                    <div className="card-img-r">
                        <img src={Equal}/>
                    </div>
                    <div className="card-reward">
                        <span className="rft">Total Reward</span>
                        <span className="font-big">${valueWithCommas(totalMoney || 0)}</span>
                        { topYearProjection === firstYear() ?
                            (introPoints || unitForPoints || points) ?
                                <><br/> <small className="invisible">Total Reward</small></> : null :
                            <><br/> <small>{`($${valueWithCommas(totalMoneyPerYear || 0)}/year)`}</small></>
                        }
                    </div>
                </div>

                {/*<div className="card-points card-reward-sm">
                    <div className="xs-view-card-reward w-100">
                        <span className="rft fs-24">Total Reward</span>
                        <span className="font-big">${valueWithCommas(totalMoney || 0)}</span>
                    </div>
                    <div className="card-r ml-5 d-flex align-items-center">
                        <div className="card-img-r">
                            <img src={Plus} style={!isReward ? {opacity: '0.5'} : null}/>
                        </div>
                        <div className="ml-4">
                            <span className={`rft ${!isReward ? "color-gray" : ""}`}>
                                Reward from Transactions
                             </span>
                            <div className="d-flex a-center">
                                <span className={`font-big ${!isReward ? "color-gray" : ""}`}>${valueWithCommas(money || 0)}</span>
                                { !!(rewardDetail || []).length &&
                                <Tooltip
                                    title={getToolTipConstant(rewardDetail || [])}
                                    trigger={"click"}
                                >
                                    <img className="help-icon" src={Help} style={!isReward ? {opacity: '0.5'} : null}/>
                                </Tooltip>
                                }
                            </div>
                            {
                                points ?
                                    <small className={!isReward ? "color-gray" : ""}>{`(${commaSeparator(points || 0)} Points)`}</small> :
                                    (introPoints || unitForPoints) ?
                                        <small className="invisible">Reward</small> : null
                            }
                        </div>
                    </div>

                    <div className="card-bonus d-flex align-items-center">
                        <div className="card-img-r">
                            <img src={Plus} style={!isIntro ? {opacity: '0.5'} : null}/>
                        </div>
                        <div className="ml-4">
                            <span className={`rft ${!isIntro ? "color-gray" : ""}`}>
                                Intro Bonus
                            </span>
                            <div className="d-flex a-center">
                                <span className={`font-big ${!isIntro ? "color-gray" : ""}`}>${valueWithCommas(introMoney || 0)}</span>
                                { !!(introOfferDetail || []).length &&
                                <Tooltip
                                    title={getToolTipConstant(introOfferDetail || [])}
                                    trigger={"click"}
                                >
                                    <img className="help-icon" src={Help} style={!isIntro ? {opacity: '0.5'} : null}/>
                                </Tooltip>
                                }
                            </div>
                            { (introPoints || unitForPoints) ?
                                <small className={!isIntro ? "color-gray" : ""}>
                                    {`(${commaSeparator(introPoints || 0)} ${unitForPoints})`}
                                </small> :
                                points ?
                                    <small className="invisible">Intro Bonus</small> : null
                            }
                        </div>
                    </div>

                    <div className="card-fee d-flex align-items-center">
                        <div className="card-img-r">
                            <img src={Minus} style={!isAnnual ? {opacity: '0.5'} : null}/>
                        </div>
                        <div  className="ml-4">
                            <span className={`rft ${!isAnnual ? "color-gray" : ""}`}>
                                Annual Fee
                            </span>
                            <div className="d-flex a-center">
                                <span className={`font-big ${!isAnnual ? "color-gray" : ""}`}>${annualFee}</span>
                                { !!(annualFeeDetail || []).length &&
                                    <Tooltip
                                        title={getToolTipConstant(annualFeeDetail || [])}
                                        trigger={"click"}
                                    >
                                        <img className="help-icon" src={Help} style={!isAnnual ? {opacity: '0.5'} : null}/>
                                    </Tooltip>
                                }
                            </div>
                            { (introPoints || unitForPoints || points) ? <small className="invisible">Annual Fee</small> : null }
                        </div>
                    </div>

                </div>*/}

                <MediaQuery maxWidth={991}>
                    <div className="mob-card-table card-detail-mobile-section m-0">
                        <div className="mc-detail card-features mb-0">
                            <div className="total-rewards">

                                <div className={`${topYearProjection === firstYear() ? "" : "no-top-padding"}`}>
                                    <h4 className="mb-0 d-flex align-items-center">
                                        <b>Total Reward:</b>
                                        <div className={`align-items-center ml-1 value ${topYearProjection === firstYear() ? "" : "is-top-padding"}`}>
                                            <div>${valueWithCommas(totalMoney || 0)}</div>
                                            { topYearProjection !== firstYear() ?
                                                <>
                                                    <div className="fs-12">
                                                        {`($${valueWithCommas(totalMoneyPerYear || 0)}/year)`}
                                                    </div>
                                                </> : null
                                            }
                                        </div>
                                    </h4>
                                </div>

                            </div>
                            <div className="card-left mob-card-left">
                                <div className="cl-item d-flex">
                                    <img className="w-10 mb-auto mt-2 mr-1" src={Plus} />
                                    <div>
                                        <span className={`cl-t`}>Reward from Transactions</span>
                                        <span className="cl-info">
                                            ${valueWithCommas(money || 0)} &nbsp;
                                            { !!(rewardDetail || []).length &&
                                                <Tooltip
                                                    title={getToolTipConstant(rewardDetail || [])}
                                                    trigger={"click"}
                                                >
                                                    <img src={Help}/>
                                                </Tooltip>
                                            }
                                        </span>
                                        <br/>
                                        { !!points && <small>{`(${commaSeparator(points || 0)} Points)`}</small> }
                                    </div>
                                </div>
                                {
                                    topIncludedIntroBonus ?
                                        <div className="cl-item d-flex">
                                            <img className="w-10 mb-auto mt-2 mr-1" src={Plus} />
                                            <div>
                                                <span className={`cl-t`}>Intro Bonus</span>
                                                <span className="cl-info">
                                                    ${valueWithCommas(introMoney || 0)} &nbsp;
                                                    { !!(introOfferDetail || []).length &&
                                                        <Tooltip
                                                            title={getToolTipConstant(introOfferDetail || [])}
                                                            trigger={"click"}
                                                        >
                                                            <img src={Help}/>
                                                        </Tooltip>
                                                    }
                                                </span>
                                                { (introPoints || unitForPoints) ?
                                                    <>
                                                        <br/>
                                                        <small>
                                                            {`(${commaSeparator(introPoints || 0)} ${unitForPoints})`}
                                                        </small>
                                                    </> : null
                                                }
                                            </div>
                                        </div> : null
                                }
                                <div className="cl-item d-flex">
                                    <img className="w-10 mb-auto mt-10 mr-1" src={Minus} />
                                    <div>
                                        <span className={`cl-t`}>Annual Fee</span>
                                        <span className="cl-info">
                                            ${commaSeparator(toFixedToDecimal(annualFee, 2))} &nbsp;
                                            { !!(annualFeeDetail || []).length &&
                                                <Tooltip
                                                    title={getToolTipConstant(annualFeeDetail || [])}
                                                    trigger={"click"}
                                                >
                                                    <img src={Help}/>
                                                </Tooltip>
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="cl-item d-flex">
                                    <img className="w-10 mb-auto mt-10 mr-1 hidden-visibility" src={Minus} />
                                    <div>
                                        <span className="cl-t">Reward Rate</span>
                                        <span className="cl-info">
                                            {rewardRate} &nbsp;
                                            { !!(rewardRateGeneralDetail || []).length &&
                                                <Tooltip
                                                    title={getToolTipConstant(rewardRateGeneralDetail || [])}
                                                    trigger={"click"}
                                                >
                                                    <img src={Help}/>
                                                </Tooltip>
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>
    
                {/* CardDetailsByGraph used to be here, now moved to SeeMoreDetails */}

                {
                    selectedCard === 0 ? null :
                        <img
                            src={ArrowBlack}
                            className={`black-arrow ${selectedCard === 0 ? "is-disabled cursor-not-allowed" : ""}`}
                            onClick={() => selectedCard === 0 ? null : onCardChange(selectedCard === 0 ? cardLength - 1 : selectedCard - 1)}
                        />
                }
                {
                    cardLength - 1 === selectedCard ? null :
                        <img
                            src={ArrowBlack}
                            className={`black-arrow right ${cardLength - 1 === selectedCard ? "is-disabled cursor-not-allowed" : ""}`}
                            onClick={() => cardLength - 1 === selectedCard ? null : onCardChange(cardLength - 1 === selectedCard ? 0 : selectedCard + 1)}
                        />
                }

                <SeeMoreDetails {...{
                    moreDetailInfo,
                    moreDetailNotes,
                    cardDetailsByGraphObj,
               }}/>

            </div>
        );
    }
}

export default CardDetails;
