import React from "react"
import {connect} from "react-redux";
import moment from "moment"
import {cloneDeep, max, orderBy} from "lodash"
import { Spin, Skeleton, Divider } from "antd";
import MediaQuery from "react-responsive";
import CardDetails from "../../../Dashboard/Components/SecondStep/component/CardDetails"
import CardDetailsCarousel from "../../../Dashboard/Components/SecondStep/component/CardDetailsCarousel"
import AllCardsView from "../../../Dashboard/Components/SecondStep/component/AllCardsView"
import {
  dateFormat,
  commaSeparator,
  getStoredDates,
  getDateRanges,
  getDateQuery,
  getSelectedDate,
  getCardsListByYearWise,
  CREDIT_SCORE_700,
  CREDIT_SCORE_600_700,
  CREDIT_SCORE_STARTER,
  CURRENT_YEAR,
  CURRENT_MONTH_SHORT,
  CURRENT_MONTH_LONG,
  FILTER_CARDS,
  COSTS_BENCHMARK,
} from "../../../../../utils/constants";
import DotImage from "../../../../../assets/images/dashboard/dot.svg"
import DotFill from "../../../../../assets/images/dashboard/dot-fill.svg"
import {Loading} from '../../../../../common/components/Loading/Loading';
import { setCards, fetchCards } from "../../../../../redux/modules/cards/cardsActions";
import {setResultSetting} from "../../../../../redux/modules/resultSetting/resultSettingActions";
import EnableCardsChart from "../../../Dashboard/Components/SecondStep/component/EnableCardsChart";
import FilterSettings from "../../../Dashboard/Components/SecondStep/component/FilterSettings";
// import CardsFilter from "../../../Dashboard/Components/SecondStep/component/CardsFilter";
import SettingModal from "../../../Dashboard/Components/SecondStep/component/SettingModal";
import RewardCalculationModal from "../../../Dashboard/Components/SecondStep/component/RewardCalculationModal";
import RecommendedCards from "../../../Dashboard/Components/SecondStep/component/RecommendedCards"
import CardDetailsModal from "../../../Dashboard/Components/SecondStep/component/CardDetailsModal"
import YearProjection from "../../../Dashboard/Components/SecondStep/component/YearProjection"
import TopSpending from "../../../Dashboard/Components/SecondStep/component/TopSpending";
import "../../../Dashboard/Components/SecondStep/datePicker.scss";
import {Tooltip} from "antd";
import Help from "../../../../../assets/images/dashboard/help-icon.svg";
import {Link} from "react-router-dom";



/**
 *  note: this is an edited version of Auth/Dashboard/components/SecondStep.
 *    + refactoring that file, as implemented now, was a headache. 
 *    + this is an edited version of it used for the current best cards page.
 *
 */
class SecondStepBenchmark extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isRunTested: false,
      isSettingModal: false,
      isCalculationModal: false,
      isYearChanging: false,
      isCardDetailsModal: false,
      activeTab: "all",
      selectedDate: 1,
      selectedCard: 0,
      selectedCardInfo: {},
      selectedRange: [moment(new Date(new Date().setFullYear(new Date().getFullYear() - 1))), moment()],
      enableCardLength: 0,
      selectedFeatures: [],
      selectedCategories: []
    }
  }
  
  // 
  componentDidMount() {
    const { cardList, fetchCardsLoaded, fetchCardsLoading, isAccountsLoading, isManualCost } = this.props
    if(!((cardList || []).length && fetchCardsLoaded) && !fetchCardsLoading && !isAccountsLoading && !isManualCost) {
      this.onGetCardsList()
    }
    if(Object.keys(getStoredDates() || {}).length) {
      const { dateType, date } = getStoredDates() || {}
      this.setState({
        selectedDate: Number(dateType) || 1,
        selectedRange: (date || []).length && Number(dateType) === 6 ? [moment(date[0]), moment(date[1])] : this.state.selectedRange
      })
    }
  }
  
  //
  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.isCardRequest) {
      this.props.onCardRequestChange(false)
      this.onGetCardsList()
    } else if(nextProps.resettingCard) {
      this.onCardChange(0)
    }
  }

  onGetCardsList = async () => {
    const { getEnableCardsIds, onFetchCards, onSetCards, onPlaidModalChange } = this.props

    const ids = getEnableCardsIds()

    return;
  }

  onTabChange = (value) => {
    this.setState({
      activeTab: value,
      selectedCard: 0
    })
  }
  
  onDateChange = (value) => {
    const selectedRange = getDateRanges(value)
    this.setState({
      selectedDate: value,
      selectedRange
    });
  }
  
  onCardChange = (value) => {
    this.setState({
      selectedCard: value
    })
  }
  
  onSelectCard = (info) => {
    const { isCardDetailsModal } = this.state
    this.setState({
      isCardDetailsModal: !isCardDetailsModal,
      selectedCardInfo: isCardDetailsModal ? {} : info
    })
  }
  
  onRangeDatesChange = (selectedRanges) => {
    this.setState({
      selectedRange: selectedRanges
    });
  }

  getSelectedDate = () => {
    const { isManualCost } = this.props;
    const { selectedRange, selectedDate } = this.state;
    if(isManualCost) {
      return "12 Months"
    } else if(selectedDate === 6) {
      const date1 = ((selectedRange && selectedRange[0] && selectedRange[0].clone()) || (selectedRange && selectedRange[1] && selectedRange[1].clone()) || moment()).format(dateFormat);
      const date2 = ((selectedRange && selectedRange[1] && selectedRange[1].clone()) || (selectedRange && selectedRange[0] && selectedRange[0].clone()) || moment()).format(dateFormat);
      return `${date1} - ${date2}`;
    } else {
      return getSelectedDate(selectedDate)
    }
  }
  
  getCardsList = () => {
    const { selectedCategories, selectedFeatures } = this.state
    
    let list = cloneDeep(this.props.cardList)
    
    /*if (activeTab !== "all") {
      list = list.filter(x => {
        return (x.card.category || []).filter(y => {
          return [(activeTab || "").toLowerCase()].some(element => (y || "").toLowerCase().includes(element))
        }).length > 0;
      });
    }*/

    list = orderBy(list, ["totalMoney", 'cardName'], "desc");

    if((selectedCategories || []).length || (selectedFeatures || []).length) {
      return FILTER_CARDS(list, selectedCategories, selectedFeatures);
    } else {
      return list;
    }
  }
  
  getTotalRewards = () => {
      const value = (this.getCardsList() || []).length && this.getCardsList()[0].totalMoney || 0
      return ((value) || 0).toFixed(2) || '0.00';
  }

  onFilterChange = (type, value) => {
    let list = this.state[type]
    if((list || []).includes(value)) {
      list = (list || []).filter(x => x !== value)
    } else {
      list.push(value)
    }
    this.setState({
      [type]: list,
      selectedCard: 0
    })
  }

  onDismissFilter = () => {
    this.setState({
      selectedFeatures: [],
      selectedCategories: [],
      selectedCard: 0
    })
  }

  onModalChange = () => {
    this.setState({
      isSettingModal: !this.state.isSettingModal
    })
  }

  onApplyingSetting = () => {
    this.props.onCardRequestChange(true)
    this.onModalChange()
    this.onCardChange(0)
  }

  onGetSelectedDate = () => {
    const { selectedDate } = this.state
    if(selectedDate === 1) {
      return "12 Months"
    } else if(selectedDate === 2) {
      return "6 Months"
    } else if(selectedDate === 3) {
      return "3 Months"
    } else if(selectedDate === 4) {
      return "1 Month"
    } else if(selectedDate === 5) {
      return "Max (12 Months)"
    } else if(selectedDate === 6) {
      return this.getSelectedDate()
    } else if(selectedDate === 7) {
      return "9 Months"
    }
  }

  getSelectedCard = () => {
    const { getEnableCardsIds, banks } = this.props
    const length = (getEnableCardsIds() || []).length

    let text = ""
    if(length === 1) {
      banks.forEach(bank => {
        bank.accounts.forEach(account => {
          if(account.enabled) {
            text = (account.official_name || account.name)
          }
        })
      })
    } else {
      text = `${length} of your cards.`
    }

    return text
  }

  onCalculationModalChange = () => {
    this.setState({
      isCalculationModal: !this.state.isCalculationModal
    })
  }

  onYearProjectionChange = (value) => {
    this.setState({isYearChanging: false, selectedCard: 0})
    this.props.onSetYearProjection({topYearProjection: value})
    setTimeout(() => localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state)), 100)
    /*setTimeout(() => {
      this.setState({isYearChanging: false})
    }, 500)*/
  }

  onIncludeIntroChange = () => {
    this.setState({selectedCard: 0})
    this.props.onSetYearProjection({topIncludedIntroBonus: !this.props.topIncludedIntroBonus})
    setTimeout(() => localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state)), 100)
  }

  render() {
    const { selectedDate, selectedCard, selectedCardInfo, selectedRange, isRunTested, selectedFeatures, selectedCategories, isSettingModal, isCalculationModal, isYearChanging, isCardDetailsModal } = this.state
    const {
      isManualCost,
      cardList,
      statics,
      fetchCardsFailure,
      fetchCardsLoading,
      isAccountsLoading,
      getEnableCardsIds,
      fetchAccountsFailure,
      errorMessage,
      avgSpending,
      spending,
      isFindingCards,
      topYearProjection,
      topIncludedIntroBonus,
    } = this.props;

    let object = {}
    if (isRunTested) {
      object.visible = false
    }
    
    const filteredCards = this.getCardsList() || [];

    const loading = isAccountsLoading || fetchCardsLoading || isFindingCards
    const loadingFailed = fetchCardsFailure || fetchAccountsFailure

    return (
      
      <div id="content" className="">

        {
          // 
          loading ? <div className="text-center mt-100"><Loading isInteral={true} isCenter={true}/></div> :
            
            <div className="card luci-card second-step">
            
            
              <div className="card-body" style={{padding: "0px 5px"}}>
              
                {/* title */}
                <h1 className="luci-title" style={{fontSize: "26px", letterSpacing: "-0.2px"}}>
                  Best Credit Cards
                  <span style={{display: "inline-block"}}>
                    &nbsp;-&nbsp;{CURRENT_MONTH_SHORT} {CURRENT_YEAR}
                  </span>
                </h1>
                
                {/* smaller text */}
                <div className="mb-1">
                  This recommendation is based on&nbsp;
                  <span style={{display: "inline-block"}}>
                    average American spending.
                    <Tooltip
                        title={`
                          On average, Americans spend (monthly): 
                          $${COSTS_BENCHMARK.grocery} on Groceries / 
                          $${COSTS_BENCHMARK.gas} on Gas / 
                          $${COSTS_BENCHMARK.restaurant} on Restaurants / 
                          $${COSTS_BENCHMARK.recreation} on Recreation / 
                          $${COSTS_BENCHMARK.travel} on Travel / 
                          $${COSTS_BENCHMARK.other} on Other
                        `}
                        trigger={"click"}
                    >
                      <img className="help-icon ml-2" src={Help}/>
                    </Tooltip>
                  </span>
                  <br/>
                  Get personalized recommendations<a href="/dashboard" className="ml-1 ga-recsFlowClick ga-recsFlowClick-section1">here</a>!
                </div>
                <div className="mb-1">
                </div>

                {/* loading error */}
                {loadingFailed ?
                    <div className="mt-60 text-center w-100">
                      <h3>{ errorMessage || "Oops, Something went wrong. Please try again later." }</h3>
                    </div> : null
                }
              </div>
              
              {/* main content */}
              {!loadingFailed && !loading ?
            
                <div className="card-body padd-xs">
                
                  {/* dropdown filter settings (new) */}
                  <FilterSettings {...{
                    selectedCategories,
                    selectedFeatures,
                    onDismissFilter: this.onDismissFilter,
                    onFilterChange: this.onFilterChange,
                    isIncludeIntroBonus: topIncludedIntroBonus,
                    onIncludeIntroChange: this.onIncludeIntroChange,
                    yearProjection: topYearProjection,
                    onYearProjectionChange: this.onYearProjectionChange,
                  }}/>
                  
                  {/* card details */}
                  <CardDetailsCarousel {...{
                    getCardsList: this.getCardsList,
                    onGetDate: this.getSelectedDate,
                    avgSpending,
                    spending,
                    selectedDate,
                    topYearProjection,
                    topIncludedIntroBonus,
                    selectedCategories,
                    selectedFeatures,
                  }}/>
                  
                  {/* divider */}
                  <Divider style={{marginTop: 25, marginBottom: 30}}/>
                  
                  {/* recommended cards */}
                  <RecommendedCards {...{
                    onSelectCard: this.onSelectCard,
                    isBenchmarkMode: true, 
                    backToFirstStep: null,
                    yearProjection: topYearProjection,
                    isIncludeIntroBonus: false, // override to exclude
                  }}/>
                  
                  <MediaQuery minWidth={767}>
                    <AllCardsView
                      cardList={cardList}
                      onSelectCard={this.onSelectCard}
                      allCardsYearProjection={topYearProjection}
                    />
                  </MediaQuery>
                  
                  {/* button */}
                  <div className="btn-area" style={{marginBottom: "80px", marginTop: "20px", display: "flex", justifyContent: "center"}}>
                    <Link to={"/dashboard"}>
                      <button
                          className="btn luci-btn primary-btn font-weight-bold pt-3 pb-3 ga-recsFlowClick ga-recsFlowClick-section3"
                          type="submit"
                          style={{display: "flex", alignItems: "center"}}
                      >
                        Earn More with<br/>Personalized Recommendations!
                      </button>
                    </Link>
                  </div>
                </div>
                
                : null
              }
            </div>
        }
        
        {
          isCardDetailsModal ?
            <CardDetailsModal
              selectedCardInfo={selectedCardInfo || {}}
              onCancel={this.onSelectCard}
            /> : null
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  state: state,
  banks: state.accounts.banks || [],
  cardList: getCardsListByYearWise(cloneDeep(state.resultSetting.topIncludedIntroBonus ? state.cards?.cardsDetails?.results : state.cards?.cardsDetails?.noBonus_results), state.cards?.cardsDetails?.cards || {}, state.resultSetting.topYearProjection) || [],
  statics: state.cards.cardsDetails.transactions_statistics || [],
  avgSpending: state.cards.cardsDetails.avgSpending || "",
  spending: state.cards.cardsDetails.spending || "",
  fetchCardsLoaded: state.cards.fetchCardsLoaded || false,
  fetchCardsLoading: state.cards.fetchCardsLoading || false,
  fetchCardsFailure: state.cards.fetchCardsFailure || false,
  errorMessage: state.cards.errorMessage || false,
  fetchAccountsFailure: state.accounts.fetchAccountsFailure || false,
  topYearProjection: state.resultSetting.topYearProjection,
  topIncludedIntroBonus: state.resultSetting.topIncludedIntroBonus
});

const mapDispatchToProps = dispatch => {
  return {
    onSetCards: data => {
      dispatch(setCards(data));
    },
    onFetchCards: data => {
      dispatch(fetchCards(data));
    },
    onSetYearProjection: (data) => {
      dispatch(setResultSetting(data))
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondStepBenchmark);
