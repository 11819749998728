import React, { useState, useEffect }  from "react";
import ReactWOW from 'react-wow'
import TitleLine  from "../../assets/images/title-line.svg";
import HiWa  from "../../assets/images/hiw-a.svg";
import HiWb  from "../../assets/images/hiw-b.svg";
import HiWc  from "../../assets/images/hiw-c.svg";
import Lock  from "../../assets/images/lock-black.svg";
import { getAuthToken }  from "../../services/ApiService";
import {Link} from "react-router-dom";
import { CURRENT_YEAR, CURRENT_MONTH_SHORT } from "../../utils/constants.js";


const HowItWorks = (props) => {
    const [activeNo, setActiveNo] = useState(1);
    const activeArray = {
        1: {
            image: HiWa
        },
        2: {
            image: HiWb
        },
        3: {
            image: HiWc
        }
    };
    let isChanging = false

    useEffect(() => {
        window.onscroll = () => {
            const howItWorkElement = document.getElementById("HowItWorks")
            if(!isChanging && howItWorkElement && howItWorkElement.offsetHeight < window.scrollY) {
                isChanging = true
            }
        }
        
        if (window.location.href.toLowerCase().includes('#howitworks')) {
            setTimeout(() => {
              const howItWorks  = document.querySelector("#HowItWorks");
              if (howItWorks) {
                window.scrollTo(0, howItWorks.offsetTop);
              }
            }, 500);
        }
        
        const interval = setInterval(() => {
            isChanging && setActiveNo(seconds => seconds + 1 === 4 ? 1 : seconds + 1 );
        }, 5000);
        return () => clearInterval(interval);
    }, [])

    const onChange = (value) => {
        setActiveNo(value)
    };

    return (
      <section className="how-it-works" id="HowItWorks">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ReactWOW>
                <h2>How it works</h2>
              </ReactWOW>
              <img src={TitleLine} className="title-line" alt=""/>
            </div>
            <div className="col-lg-6 hidden-md-down">
              <div className="hiw-left">
                <img src={activeArray[activeNo || 1].image || null} className="hl-img" alt=""/>
              </div>
            </div>
            <div className="col-lg-6 hiw-right-area">
              <div className="hiw-right">
                <div className="hiw-points">
                  <ReactWOW >
                    <div className="h-point">
                      <span className={`point-no cursor-pointer ${activeNo === 1 ? 'active': ''}`} onClick={() => onChange(1)}>1</span>
                      <h3><img className="w-30 pb-10" src={Lock} /> Securely share with luci</h3>
                      <p>Use Plaid’s bank-level security to securely share how you spend your money or manually enter your spending estimates.
                      </p>
                    </div>
                  </ReactWOW>
                  <ReactWOW>
                  <div className="h-point">
                    <span className={`point-no cursor-pointer ${activeNo === 2 ? 'active': ''}`} onClick={() => onChange(2)}>2</span>
                    <h3>Let luci do the work</h3>
                    <p>Using patent pending technology and your spending habits, luci calculates how much you could earn from all the best reward cards available.</p>
                  </div>
                  </ReactWOW>
                  <ReactWOW >
                  <div className="h-point">
                    <span className={`point-no cursor-pointer ${activeNo === 3 ? 'active': ''}`} onClick={() => onChange(3)}>3</span>
                    <h3>Maximize your rewards</h3>
                    <p>Confidently know which card maximizes your rewards. No more missing out on rewards by having the wrong credit card.</p>
                  </div>
                  </ReactWOW>
                </div>
              </div>
            </div>
            
            <ReactWOW>
              <div className="col-lg-12" data-wow-delay="0.8s">
                <div className="btn-area">
                  <button
                      className="btn luci-btn primary-btn font-weight-bold pt-3 pb-3"
                      type="submit"
                      onClick={() => props.history.push("/dashboard")}
                  >
                      Find Your Best Card & Earn More!
                  </button>
                  <Link to="/current-best-cards" className="nav-link lh-25" style={{marginTop: 20, textDecoration: "underline"}}>
                    Best Credit Cards for {CURRENT_MONTH_SHORT} {CURRENT_YEAR}
                  </Link>
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
      </section>
    );
};

export default HowItWorks;
