import React from "react";
import { Button, Checkbox, Popover, Radio, Divider } from "antd";
import { getCategoriesList, getFeaturesList } from "../../../../../../utils/constants"
import Arrow from "../../../../../../assets/images/Inner/arrow.svg";
import CloseCheck from "../../../../../../assets/images/close.png";
import {firstYear, lastYear, secondYear} from "../../../../../../utils/constants";
import _ from "lodash";


const FilterSettings = (props) => {

  // 
  const {
    
    // filters
    selectedCategories,
    selectedFeatures,
    onDismissFilter,
    onFilterChange,
    
    // intro bonus toggle
    isIncludeIntroBonus,
    onIncludeIntroChange,
    
    // projection years
    yearProjection, 
    onYearProjectionChange,
    
  } = props || {};
  
  // filter heights
  const heightCat = 30 * Math.ceil(getCategoriesList().length/2) + 30; // hacky 
  const heightFeat = 60 * Math.ceil(getFeaturesList().length/2) + 30; // hacky
  
  // projections
  const projYears = [firstYear, secondYear, lastYear]; // note that these are fns for some reason
  
  // add classes for ga
  const setGaClassNames = () => {
    setTimeout(() => {
      
      // cat
      for (let category of getCategoriesList()) {
        const cn = "ga-facet-filter-cat-" + category.replaceAll(" ","_");
        const elements = document.getElementsByClassName(cn);
        for (let element of elements) {
          const children = element.children;
          for (let child of children) {
            child.classList.add(cn);
          }
        }
      }
      
      // features
      for (let feature of getFeaturesList()) {
        const cn = "ga-facet-filter-feat-" + feature.replaceAll(" ","_");
        const elements = document.getElementsByClassName(cn);
        for (let element of elements) {
          const children = element.children;
          for (let child of children) {
            child.classList.add(cn);
          }
        }
      }
      
      // proj years
      for (let yr of projYears) {
        const cn = `ga-facet-filter-projyear${yr()}`;
        const elements = document.getElementsByClassName(cn);
        for (let element of elements) {
          const children = element.children;
          for (let child of children) {
            child.classList.add(cn);
          }
        }
      }
      
    }, 100); // delay ensures rendering complete
  };
  
  // 
  const filtersContent = (
    <div style={{width: ""}}>
    
      {/* intro bonus toggle */}
      <h6 className="pl-2 pt-10 mb-0">Include Intro Bonus</h6>
      <div className="dashboard-wrapper mt-3">
        <div className="bank-area mb-0 ml-3">
          <div className="toggle">
            <label className="switch ga-facet-filter-introbonus">
              <input type="checkbox" checked={isIncludeIntroBonus} onChange={onIncludeIntroChange}/>
              <span className="slider round ga-facet-filter-introbonus"/>
            </label>
          </div>
        </div>
      </div>
      <Divider style={{marginTop: 5, marginBottom: 5}}/>
            
      {/* proj years */}
      <h6 className="pl-2 pt-10 mb-0">Projection</h6>
      <div className="p-3">
        <Radio.Group value={yearProjection} onChange={(e) => onYearProjectionChange(e.target.value)}>
          {_.map(projYears, yr => 
            <Radio value={yr()} key={yr()} className={`ga-facet-filter-projyear${yr()}`}>{yr()}-Year</Radio>
          )}
        </Radio.Group>
      </div>
      <Divider style={{marginTop: 5, marginBottom: 5}}/>
            
      {/* filters - cat */}
      <h6 className="pl-2 pt-10 mb-0">Categories</h6>
      <div className="pt-3 pr-1 pb-3 pl-3" style={{display: "flex", flexDirection: "column", flexWrap: "wrap", height: heightCat + "px"}}>
          {_.map(getCategoriesList() || [], (category, index) => (
            <div className="mb-2" key={index.toString()}>
                <Checkbox
                    checked={(selectedCategories || []).includes(category)}
                    onChange={() => onFilterChange("selectedCategories", category)}
                    className={"ga-facet-filter-cat-" + category.replaceAll(" ","_")}
                >
                    {category}
                </Checkbox>
            </div>
          ))}
      </div>
      <Divider style={{marginTop: 5, marginBottom: 5}}/>
      
      {/* filters - feat */}
      <h6 className="pl-2 pt-10 mb-0">Features</h6>
      <div className="pt-3 pr-1 pb-3 pl-3" style={{display: "flex", flexDirection: "column", flexWrap: "wrap", height: heightFeat + "px"}}>
        {_.map(getFeaturesList() || [], (feature, index) => (
          <div className="mb-2" key={index.toString()}>
            <Checkbox
              checked={(selectedFeatures || []).includes(feature)}
              onChange={() => onFilterChange("selectedFeatures", feature)}
              className={"ga-facet-filter-feat-" + feature.replaceAll(" ","_")}
            >
              {feature}
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  );
  
  // 
  const selection = [...(selectedFeatures || []), ...(selectedCategories)]
  
  // 
  return (
    <div className="card-filter mb-3">
      <div className="filter">
        <span className="feature-filter">
          <div className="form-group mb-0">
            <Popover content={filtersContent} trigger={"click"} placement="bottomLeft" title={null} onOpenChange={setGaClassNames}>
              <Button className="ga-filter" block={true}>
                <div className="pl-1 pr-1 ga-filter" style={{width: "170px"}}>
                
                  {/* text */}
                  <span className="mr-2 ml-2 ga-filter">
                    { (selection || []).length ? `Filters (${(selection || []).length})` : "Customize Settings" }
                  </span>
                  
                  {/* arrow */}
                  <img src={Arrow} className="arrow-tab-more ga-filter"/>
                  
                  {/* clickable X */}
                  { (selection || []).length ?
                    <span className="mr-2" style={{float: "right", width: 0}}>
                      <img src={CloseCheck} className="arrow-tab-more" onClick={() => onDismissFilter()}/>
                    </span>
                    : null
                  }
                </div>
              </Button>
            </Popover>
          </div>
        </span>
      </div>
    </div>
  );
  
}

export default FilterSettings
