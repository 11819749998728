import React from "react";
import {Button, Checkbox, Popover} from "antd";
import { getCategoriesList, getFeaturesList } from "../../../../../../utils/constants"
import Arrow from "../../../../../../assets/images/Inner/arrow.svg";
import CloseCheck from "../../../../../../assets/images/close.png";


const EnableCardsChart = (props) => {

  const { selectedCategories, selectedFeatures, onDismissFilter, onFilterChange, onCalculationModalChange, isTop } = props || {}
  const filters = (
    <>
      <h6 className="pl-2 pt-10 mb-0">Categories</h6>
      <div className="p-3">
          {
              (getCategoriesList() || []).map((category, index) => (
                  <p key={index.toString()}>
                      <Checkbox
                          checked={(selectedCategories || []).includes(category)}
                          onChange={() => onFilterChange("selectedCategories", category)}
                      >
                          {category}
                      </Checkbox>
                  </p>
              ))
          }
      </div>
      <h6 className="pl-2 pt-10 mb-0">Features</h6>
      <div className="p-3">
        {
          (getFeaturesList() || []).map((feature, index) => (
            <p key={index.toString()}>
              <Checkbox
                checked={(selectedFeatures || []).includes(feature)}
                onChange={() => onFilterChange("selectedFeatures", feature)}
              >
                {feature}
              </Checkbox>
            </p>
          ))
        }
      </div>
    </>
  );
  
  const selection = [...(selectedFeatures || []), ...(selectedCategories)]

  return(
    <div className="card-filter mb-3">
        {
            !isTop ?
                <div className="free-text">
                    Browse your personalized recommendation from luci ranked by savings and rewards based on your spending.
                    <b className="color-link cursor-pointer ml-1" onClick={onCalculationModalChange}>Change Calculation Settings</b>
                </div> : null
        }

      <div className="filter">
        <span className="feature-filter">
          <div className="form-group mb-0">
            <Popover content={filters} trigger={['click']} placement="bottomLeft" title={null}>
              <Button className="">
                { (selection || []).length ? `Filters (${(selection || []).length})` : "Overall" }
                  &nbsp;<img src={Arrow} className="arrow-tab-more"/>
                  { (selection || []).length ?
                    <span> &nbsp;&nbsp;&nbsp;
                      <img src={CloseCheck} className="arrow-tab-more" onClick={() => onDismissFilter()}/>
                    </span> : null
                  }
              </Button>
            </Popover>
          </div>
        </span>
      </div>

    </div>
  )
}

export default EnableCardsChart
