import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import {cloneDeep} from "lodash";
import Header from "../Header";
import Sidebar from "../Sidebar";
import SecondStepBenchmark from "./Components/SecondStepBenchmark";
// import PlaidErrorModal from "../Auth/Components/FirstStep/component/PlaidErrorModal";
// import NoCreditCardModal from "../Auth/Components/FirstStep/component/NoCreditCardModal";
import { ApiService } from "../../../services/ApiService";
import {setAccounts, setReLoginRequiredInstitutes} from "../../../redux/modules/accounts/accountsActions";
import { setCosts } from "../../../redux/modules/manualCosts/costsActions";
// import FindCardModal from "../Auth/Components/FirstStep/component/FindCardModal";
// import UsingPlaidModal from "../Auth/Components/UsingPlaidModal";
import { fetchCards, setCards } from "../../../redux/modules/cards/cardsActions";
import { setDashboardStep } from "../../../redux/modules/dashboardStep/dashboardStepActions";
import {setResultSetting} from "../../../redux/modules/resultSetting/resultSettingActions";
import {
  getStoredAccounts,
  getStoredCosts,
  removeComma,
  getDateQuery,
  getDateRanges,
  getCardsListByYearWise,
  isUserLoggedIn,
  getStoredCards,
  getManualStoredValue,
  COSTS_BENCHMARK,
} from "../../../utils/constants";
import "../../../common/styles/dashboard.scss";
import _ from "lodash";


/**
 *  notes: 
 *    + all things under Auth folder is not the best place for it.
 *    + Dashboard used to be an auth-only page, but have been modified since (manual entry mode, now current-best-card benchmark mode)
 *    + doing a refactor here was avoided because of the scale of the refactor, and the complex nature of the code
 *    + instead, this is a edited version of the components in Dashboard
 *
 */
class CurrentBestCards extends React.Component {


  /**
   *
   *
   */
  constructor(props) {
    super(props);
    this.state = {
      // currentStep: 0, // todo remove step
      isFindCardModal: false,
      isCardRequest: false,
      isFindingCards: false,
      resettingCard: false,
      isPlaidPopUp: false,
      plaidPopUpErrorMsg: "",
      isUsingPlaidPopUp: false,
      isNoCreditCardPopUp: false,
      isNewUser: false,
      institutionName: ''
    };
  }


  /**
   *
   *
   */
  async componentDidMount() {
    
    // 
    const {
      fetchAccountsLoaded,
      cardList,
      fetchCardsLoaded,
      isFirstStepComplete
    } = this.props;
    
    // set loading state
    this.setState({isFindingCards: true});
    
    // 
    this.onFindingCards();
  }
  
  

  onCardRequestChange = (value) => {
    this.setState({
      isCardRequest: value
    })
  }

  getEnableCardsIds = () => {
    const {banks} = this.props

    let ids = []
    banks.forEach(bank => {
      bank.accounts.forEach(acc => {
        if (acc.enabled) {
          ids.push(`account=${acc.account_id}`)
        }
      })
    })

    return ids || []
  }


  
  /**
   *
   *
   */
  gotoSecondStep = () => {
    this.props.onSetDashboardStep({isFirstStepComplete: true})
    this.setState({
      currentStep: 1,
      resettingCard: false
    })
  }



  
  /**
   *  fire api call to get backtest data
   *
   */
  onFindingCards = async () => {
    
    // costs
    const costs = COSTS_BENCHMARK;
    const { grocery, gas, restaurant, other, recreation, travel } = costs || {}
    
    // dates
    const dateRange = getDateRanges(1)
    const dateQuery = `&startDate=${moment((dateRange || [])[0]).format("YYYY-MM-DD")}&endDate=${moment((dateRange || [])[1]).format("YYYY-MM-DD")}&nMonths=${12}`
    const query = `?1=${removeComma(grocery)}&2=${removeComma(gas)}&4=${removeComma(restaurant)}&5=${removeComma(other)}&8=${removeComma(recreation)}&9=${removeComma(travel)}${dateQuery}`

    // fetch
    await this.props.onFetchCards({
      apiEndpoint: "free/run-backtest-manual",
      query: query,
      onSuccess: (res) => {
        this.onBanksCardOff()
        this.props.onSetCards(res || {})
        this.setState({
          isFindCardModal: false,
          isFindingCards: false,
          resettingCard: true
        });
        this.gotoSecondStep()
        localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
      },
      onFail: (response) => {
        if (response?.data?.errors && response.data.errors[0]?.param?.error_type === 'popup') {
          this.setState({
            isPlaidPopUp: true,
            institutionName: response.data.errors[0]?.param?.institutionName,
            currentStep: 0,
            plaidPopUpErrorMsg: response.data.errors[0]?.msg || ""
          });
        }
        this.setState({
          isFindingCards: false
        });
        localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state))
      }
    })
  };


  onBanksCardOff = () => {
    const { banks } = this.props

    banks.forEach(bank => {
      bank.accounts.forEach(account => {
        account.enabled = false
      })
    })

    this.props.onSetAccounts(banks);
  }

  
  // 
  render() {
    
    //
    // const { isFirstStepComplete, onSetDashboardStep } = this.props;
    
    // 
    const {
      currentStep,
      isFindCardModal,
      isCardRequest,
      isFindingCards,
      resettingCard,
      isPlaidPopUp,
      isUsingPlaidPopUp,
      institutionName,
      isNewUser,
      isNoCreditCardPopUp,
      plaidPopUpErrorMsg
    } = this.state;
    
    // render
    return (
      <div className="dashboard-wrapper">

        <Header isFirstStepComplete={true}/>
        
        <section>
          <div
            className={`wrapper profile-sidebar ${
              currentStep === 0 ? "first-step-lg" : "second-step-lg"
            }`}
          >
            <SecondStepBenchmark
              isCardRequest={isCardRequest}
              isFindingCards={isFindingCards}
              resettingCard={resettingCard}
              onCardRequestChange={this.onCardRequestChange}
              getEnableCardsIds={this.getEnableCardsIds}
              onPlaidModalChange={this.onPlaidModalChange}
            />
          </div>
        </section>
      </div>
    );
  }
}



const mapStateToProps = state =>  {
return {
  state: state || {},
  costs: COSTS_BENCHMARK,
  // isManualCost: true, // force rue
  banks: state.accounts.banks || [],
  fetchAccountsLoaded: state.accounts.fetchAccountsLoaded || false,
  cardList: getCardsListByYearWise(cloneDeep(state.resultSetting.topIncludedIntroBonus ? state.cards?.cardsDetails?.results : state.cards?.cardsDetails?.noBonus_results), state.cards?.cardsDetails?.cards || {}, state.resultSetting.topYearProjection) || [],
  fetchCardsLoaded: state.cards.fetchCardsLoaded || false,
  isFirstStepComplete: state.dashboardStep.isFirstStepComplete || false,
};
}

const mapDispatchToProps = dispatch => {
  return {
    onSetAccounts: data => {
      dispatch(setAccounts(data));
    },
    onSetReLoginRequiredInstitutes : data => {
      dispatch(setReLoginRequiredInstitutes(data));
    },
    onSetCosts: data => {
      dispatch(setCosts(data));
    },
    onFetchCards: data => {
      dispatch(fetchCards(data));
    },
    onSetCards: data => {
      dispatch(setCards(data));
    },
    onSetDashboardStep: data => {
      dispatch(setDashboardStep(data));
    },
    onSetYearProjection: (data) => {
      dispatch(setResultSetting(data))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentBestCards);
