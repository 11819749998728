import React from "react";
import ReactWOW from 'react-wow'
import cardsImg  from "../../assets/images/cards-small.svg"
import card from "../../assets/images/card.svg"
import cardItems  from "../../assets/images/card-items.svg"
import {getAuthToken} from "../../services/ApiService";
import {Link} from "react-router-dom";
import { CURRENT_YEAR, CURRENT_MONTH_SHORT } from "../../utils/constants.js";


const Banner = (props) => {
  
  return (
    <section className="banner">
      <div className="container">
        <div className="row">
        
          <div className="col-12 col-lg-6 banner-left">
            <ReactWOW animation="fadeInLeft" delay="0.5s" >
              <div className="banner-text">
                <h1><span className="s-text">Maximize </span>your <br/> credit card <span className="s-text">rewards</span>
                </h1>
                <p>luci maximizes your rewards by finding the best credit card, personalized to the way you spend.</p>
              </div>
            </ReactWOW>
            
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            
              {/* card icons */}
              <div className="card-area">
                <figure>
                  <img src={cardsImg} alt=""/>
                </figure>
              </div>
              
              {/* buttons */}
              <div className="btn-area" style={{marginTop: "-15px", marginBottom: "20px"}}>
                <button
                    className="btn luci-btn primary-btn font-weight-bold pt-3 pb-3"
                    type="submit"
                    onClick={() => props.history.push("/dashboard")}
                >
                  Find Your Best Card & Earn More!
                </button>
              </div>
              
              {/* link */}
              <div style={{marginBottom: "40px", textDecoration: "underline"}}>
                <Link to="/current-best-cards" className="nav-link lh-25">
                  Best Credit Cards for {CURRENT_MONTH_SHORT} {CURRENT_YEAR}
                </Link>
              </div>
            </div> 
          </div>
          
          
          <div className="col-12 col-lg-6 banner-right">
            <div className="card-area text-right">
              <ReactWOW animation="fadeInRight" delay="0.5s">
                <figure className="card-lg">
                  <img src={card} alt=""/>
                </figure>
              </ReactWOW>
              <ReactWOW animation="fadeInUp" delay="1s">
                <figure className="card-items">
                  <img src={cardItems} alt=""/>
                </figure>
              </ReactWOW>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
