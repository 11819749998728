import React from "react";
import ReactWOW from 'react-wow'
import TitleLine from "../../assets/images/title-line.svg";
import B1 from "../../assets/images/benifit/b-1.svg";
import B2 from "../../assets/images/benifit/b-2.svg";
import B3 from "../../assets/images/benifit/b-3.svg";
import B4 from "../../assets/images/benifit/b-4.svg";
import B5 from "../../assets/images/benifit/b-5.svg";
import B6 from "../../assets/images/benifit/b-6.svg";
import B7 from "../../assets/images/benifit/b-7.svg";
import {getAuthToken} from "../../services/ApiService";
import './benefits.scss';
import {Link} from "react-router-dom";
import { CURRENT_YEAR, CURRENT_MONTH_SHORT } from "../../utils/constants.js";

const Benefits = (props) => {

  return (
    <section className="benefits-items wow fadeInUp" data-wow-delay="0.5s" id="Support">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <ReactWOW>
              <h2>Find <span>perfect credit cards</span> with the <span>benefits</span> you're looking for</h2>
            </ReactWOW>
            <img src={TitleLine} className="title-line" alt=""/>
            <div className="denifits-item">
              <ReactWOW>
                <div className="d-item">
                  <img src={B1} alt=""/>
                  <span>No Annual Fee</span>
                </div>
              </ReactWOW>
              <ReactWOW>
                <div className="d-item">
                  <img src={B2} alt=""/>
                  <span>Cash Back</span>
                </div>
              </ReactWOW>
              <ReactWOW>
                <div className="d-item">
                  <img src={B3} alt=""/>
                  <span>Intro Bonus</span>
                </div>
              </ReactWOW>
              <ReactWOW>
                <div className="d-item">
                  <img src={B4} alt=""/>
                  <span>Building Credit</span>
                </div>
              </ReactWOW>
              <ReactWOW>
                <div className="d-item dc">
                  <img src={B5} alt=""/>
                  <span>Balance Transfer</span>
                </div>
              </ReactWOW>
              <ReactWOW>
                <div className="d-item dc dc-a">
                  <img src={B6} alt=""/>
                  <span>0% Intro APR</span>
                </div>
              </ReactWOW>
              <ReactWOW>
                <div className="d-item dc dc-b">
                  <img src={B7} alt=""/>
                  <span>Travel</span>
                </div>
              </ReactWOW>
            </div>
          </div>
          
          <ReactWOW>
            <div className="col-lg-12 text-center">
              <div className="btn-area">
                <button
                    className="btn luci-btn primary-btn font-weight-bold pt-3 pb-3"
                    onClick={() => props.history.push("/dashboard")}
                >
                  Find Your Best Card & Earn More!
                </button>
                <Link to="/current-best-cards" className="nav-link lh-25" style={{marginTop: 20, color: "white", textDecoration: "underline"}}>
                  Best Credit Cards for {CURRENT_MONTH_SHORT} {CURRENT_YEAR}
                </Link>
              </div>
            </div>
          </ReactWOW>
          
        </div>
      </div>
    </section>
  );
};

export default Benefits;
