import React from "react";
import {connect} from "react-redux";
import {cloneDeep, orderBy} from "lodash";
import { Select, Popover, Button, Checkbox, Tooltip } from "antd"
import Arrow from "../../../../../../assets/images/Inner/arrow.svg";
import Help from "../../../../../../assets/images/dashboard/help-icon.svg";
import ArrowBlack from "../../../../../../assets/images/dashboard/arrow-black.svg";
import CloseCheck from "../../../../../../assets/images/close.png";
import {
    getCardImageURL,
    commaSeparator,
    valueWithCommas,
    getFeaturesIcon,
    getCategoriesList,
    getFeaturesList,
    getToolTipConstant,
    toFixedToDecimal,
    getCardsListByYearWise,
    firstYear,
    FILTER_CARDS
} from "../../../../../../utils/constants";
import "../../../../../../common/styles/dashboard.scss"
import YearProjection from "./YearProjection";
import {setResultSetting} from "../../../../../../redux/modules/resultSetting/resultSettingActions";


const { Option } = Select;

class AllCardsView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isExpand: false,
            sorting: {
                by: "totalMoney",
                ascdsc: "dsc"
            },
            selectedCard: 0,
            selectedCategories: [],
            selectedFeatures: []
        }
    }

    onChange = () => {
        this.setState({
            isExpand: !this.state.isExpand
        }, () => setTimeout(() => window.scrollTo(0,document.body.scrollHeight), 300));
    }

    onCheckboxChange = (type, value) => {
        let list = this.state[type]
        if((list || []).includes(value)) {
            list = (list || []).filter(x => x !== value)
        } else {
            list.push(value)
        }
        this.setState({
            [type]: list,
            selectedCard: 0
        })
    }

    getFilteredList = () => {
        const { selectedCategories, selectedFeatures, sorting } = this.state
        const { by, ascdsc } = sorting || {}
        let cardList = cloneDeep(this.props.cardList || [])
        if(by) {
            cardList = cardList.map((a) => {
                return {
                    ...a,
                    cardName: a.name,
                    annualFee: a.annualFee
                };
            });
            cardList = orderBy(cardList, [by, 'cardName'], [ascdsc === 'asc' ? 'asc' : 'desc', 'asc']);
        }

        if((selectedCategories || []).length || (selectedFeatures || []).length) {

            let list = FILTER_CARDS(cardList, selectedCategories, selectedFeatures);
            cardList = list;
        }

        return cardList
    }

    onShortingChange = (type, value) => {
        this.setState({
            sorting: {
                by: type,
                ascdsc: value
            },
            selectedCard: 0
        })
    }

    getResponsiveCardTable = () => {
        const cardsLength = (this.getFilteredList() || []).length
        if(!cardsLength) {
            return (
                <div className="mob-card-table">
                    <div className="text-center">No cards found!</div>
                </div>
            )
        };
        const { selectedCard, sorting } = this.state
        const { by } = sorting || {}
        const cardObject = Object.keys((this.getFilteredList() || {})[selectedCard]).length ? (this.getFilteredList() || {})[selectedCard] : (this.getFilteredList() || {})[0]
        const { cardId, money, points, introMoney, introPoints, totalMoney, name = "", rewardInfoLong = "", link = "", features = [], annualFee = "", rewardDetail = [], introOfferDetail = [], annualFeeDetail = [], rewardRate = "", rewardRateGeneralDetail = [] } = cardObject || {}
        return(
            <div className="mob-card-table">
                <div className="card-id">
                    <div className="img-card">
                        <img
                            className="responsive-card"
                            src={getCardImageURL(cardId)}
                            onClick={() => window.open(link, "_blank")}
                        />
                    </div>
                    <div className="card-name">
                        <span className="card-nm">
                            {name}
                        </span>&nbsp;
                        { !!rewardInfoLong && <Tooltip title={rewardInfoLong} trigger={"click"}><img src={Help}/></Tooltip> }
                    </div>
                </div>
                <div className="mc-detail align-set">

                    <button
                        className={`btn luci-btn primary-btn shadow-btn font-weight-bold pt-3 pb-3 ga-ccApply ga-ccApply-section3-narrow ga-ccApply-cardId${cardId}`}
                        type="submit"
                        onClick={() => window.open(link, "_blank")}
                    >
                        Apply
                    </button>
                    <button
                        className="btn luci-btn dark-btn font-weight-bold pt-3 pb-3"
                        type="submit"
                        data-toggle="modal"
                        data-target="#exampleModal-a"
                        onClick={() => this.props.onSelectCard(cardObject)}
                    >
                        Details
                    </button>

                </div>

                <div className="mc-detail card-features mb-0">
                    <div className="total-rewards">
                        <div className={`cl-t ${by === "totalMoney" ? "link-text": ""}`}>Total Reward</div>
                        <div className="cl-info">
                                ${valueWithCommas(totalMoney || 0)}
                         </div>
                    </div>
                    <div className="card-left mob-card-left">
                        <div className="cl-item">
                            <span className={`cl-t ${by === "money" ? "link-text": ""}`}>Reward from Transactions</span>
                            <span className="cl-info">
                                +${valueWithCommas(money || 0)} &nbsp;
                                { !!(rewardDetail || []).length &&
                                    <Tooltip
                                        title={getToolTipConstant(rewardDetail || [])}
                                        trigger={"click"}
                                    >
                                        <img src={Help}/>
                                    </Tooltip>
                                }
                            </span>
                            <br/>
                            { !!points && <small>{`(${commaSeparator(points || 0)} Points)`}</small> }
                        </div>
                        <div className="cl-item">
                            <span className={`cl-t ${by === "introMoney" ? "link-text": ""}`}>Intro Bonus</span>
                            <span className="cl-info">
                                +${valueWithCommas(introMoney || 0)} &nbsp;
                                { !!(introOfferDetail || []).length &&
                                    <Tooltip
                                        title={getToolTipConstant(introOfferDetail || [])}
                                        trigger={"click"}
                                    >
                                        <img src={Help}/>
                                    </Tooltip>
                                }
                            </span>
                        </div>
                        <div className="cl-item">
                            <span className={`cl-t ${by === "annualFee" ? "link-text": ""}`}>Annual Fee</span>
                            <span className="cl-info">
                                -${commaSeparator(toFixedToDecimal(annualFee, 2))} &nbsp;
                                { !!(annualFeeDetail || []).length &&
                                    <Tooltip
                                        title={getToolTipConstant(annualFeeDetail || [])}
                                        trigger={"click"}
                                    >
                                        <img src={Help}/>
                                    </Tooltip>
                                }
                            </span>
                        </div>
                        <div className="cl-item">
                            <span className="cl-t">Reward Rate</span>
                            <span className="cl-info">
                                {rewardRate} &nbsp;
                                { !!(rewardRateGeneralDetail || []).length &&
                                    <Tooltip
                                        title={getToolTipConstant(rewardRateGeneralDetail || [])}
                                        trigger={"click"}
                                    >
                                        <img src={Help}/>
                                    </Tooltip>
                                }
                            </span>
                            <br/>
                            { !!introPoints && <small>{`(${commaSeparator(introPoints || 0)} Points)`}</small> }
                        </div>
                    </div>
                </div>

                <div className="mc-detail cat-area">
                    <span className="cat-title">Features</span>
                    {
                        (features || []).map((feature, index) => (
                            <div className="feature-tab" key={index.toString()}>
                                <img src={getFeaturesIcon(feature)} className="mr-5px"/>
                                <span>{feature}</span>
                            </div>
                        ))
                    }
                </div>

                <img
                    src={ArrowBlack}
                    className={`black-arrow ${selectedCard === 0 ? "is-disabled cursor-not-allowed" : ""}`}
                    onClick={() => selectedCard === 0 ? null : this.onCardChange(selectedCard === 0 ? cardsLength - 1 : selectedCard - 1)}
                />
                <img
                    src={ArrowBlack}
                    className={`black-arrow right ${cardsLength - 1 === selectedCard ? "is-disabled cursor-not-allowed" : ""}`}
                    onClick={() => cardsLength - 1 === selectedCard ? null : this.onCardChange(cardsLength - 1 === selectedCard ? 0 : selectedCard + 1)}
                />
            </div>
        )
    }

    onCardChange = (value) => {
        this.setState({
            selectedCard: value
        })
    }

    onYearProjectionChange = (value) => {
        this.props.onSetYearProjection({allCardsYearProjection: value})
        setTimeout(() => localStorage.setItem("LUCI_DATAS", JSON.stringify(this.props.state)), 100)
    }

    render() {
        const { onSelectCard, allCardsYearProjection } = this.props;
        const { isExpand, selectedFeatures, selectedCategories, sorting } = this.state;
        const { by, ascdsc } = sorting || {}
    
        const filters = (
          <>
              <h6 className="pl-2 pt-10 mb-0">Categories</h6>
              <div className="p-3">
                  {
                      (getCategoriesList() || []).map((category, index) => (
                        <p key={index.toString()}>
                            <Checkbox
                              checked={(selectedCategories || []).includes(category)}
                              onChange={() => this.onCheckboxChange("selectedCategories", category)}
                            >
                                {category}
                            </Checkbox>
                        </p>
                      ))
                  }
              </div>
              <h6 className="pl-2 pt-10 mb-0">Features</h6>
              <div className="p-3">
                  {
                      (getFeaturesList() || []).map((feature, index) => (
                        <p key={index.toString()}>
                            <Checkbox
                              checked={(selectedFeatures || []).includes(feature)}
                              onChange={() => this.onCheckboxChange("selectedFeatures", feature)}
                            >
                                {feature}
                            </Checkbox>
                        </p>
                      ))
                  }
              </div>
          </>
        );
    
        const selection = [...(selectedFeatures || []), ...(selectedCategories)]

        return (
            <div className="view-card">
                <div className="text-center">
                    <a className="va-title" data-toggle="collapse" href="#collapseExample-a" role="button"
                       aria-expanded="false" aria-controls="collapseExample-a" onClick={this.onChange}
                    >
                        {isExpand ? "Hide" : "View"} All Cards <br/>
                        <img src={Arrow} className="arrow-tab-more"/>
                    </a>
                </div>

                <div className="collapse" id="collapseExample-a">
                    <div className="table-responsive">
                        <div className="table-card">
                            <span className="all-card">All Cards</span>

{/*                            <div className="projection-filter-menu mt-3">
                                <YearProjection
                                    yearProjection={allCardsYearProjection}
                                    onYearProjectionChange={this.onYearProjectionChange}
                                />
                                <div className="header-card">

                                <span className="left-filter ml-auto">
                                   <div className="form-group mb-0" />
                                </span>

                                    <span className="right-filter">
                                    <div className="form-group mb-0">
                                        <Popover content={filters} trigger={['click']} placement="bottomLeft" title={null}>
                                          <Button style={{minWidth: 172}}>
                                            Filters { (selection || []).length ? ` (${(selection || []).length})` : null }
                                              &nbsp;<img src={Arrow} className="arrow-tab-more"/>
                                              { (selection || []).length ? <span> &nbsp;&nbsp;&nbsp;<img src={CloseCheck} className="arrow-tab-more" onClick={() => this.setState({selectedFeatures: [], selectedCategories: [],selectedCard: 0})}/></span> : null }
                                          </Button>
                                        </Popover>
                                    </div>
                                </span>

                                    <div className="mobile-filter">
                                        <span className="right-filter-label">Sort By</span>
                                        <span className="right-filter">

                                            <div className="form-group mb-0">

                                                <Select className="custom-dropdown-box" size="large" value={by} onChange={(value) => this.onShortingChange(value, "dsc")}>
                                                   <Option value="totalMoney">Total Reward</Option>
                                                   <Option value="money">Reward from Transactions</Option>
                                                   <Option value="introMoney">Intro Bonus</Option>
                                                   <Option value="annualFee">Annual Fee</Option>
                                                </Select>

                                            </div>

                                        </span>
                                    </div>
                                </div>
                            </div>
*/}
                            <div className="table-card-data">
                                <table className="table-card table">
                                    <colgroup>
                                        <col style={{width: '20%'}}/>
                                        <col style={{width: '20%'}}/>
                                        <col style={{width: '10%'}}/>
                                        <col style={{width: '10%'}}/>
                                        <col style={{width: '10%'}}/>
                                        <col style={{width: '10%'}}/>
                                        <col style={{width: '10%'}}/>
                                        <col style={{width: '10%'}}/>
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>Card </th>
                                            <th>Features </th>
                                            <th className="cursor-pointer" onClick={() => this.onShortingChange("totalMoney", ascdsc === "dsc" ? "asc" : "dsc")}>
                                                <span className={by === "totalMoney" ? "link-text": ""}>Total Reward</span>
                                                <div className="sorting-arrow">
                                                    <i className={`sorting-asc ${(by === "totalMoney" && ascdsc === "asc") ? "" : "gray"}`}/>
                                                    <i className={`sorting-desc ${(by === "totalMoney" && ascdsc === "dsc") ? "" : "gray"}`}/>
                                                </div>
                                            </th>
                                            <th className="cursor-pointer" onClick={() => this.onShortingChange("money", ascdsc === "dsc" ? "asc" : "dsc")}>
                                                <span className={by === "money" ? "link-text": ""}>Reward from transactions</span>
                                                <div className="sorting-arrow">
                                                    <i className={`sorting-asc ${(by === "money" && ascdsc === "asc") ? "" : "gray"}`} />
                                                    <i className={`sorting-desc ${(by === "money" && ascdsc === "dsc") ? "" : "gray"}`} />
                                                </div>
                                            </th>
                                            <th className="cursor-pointer" onClick={() => this.onShortingChange("introMoney", ascdsc === "dsc" ? "asc" : "dsc")}>
                                                <span className={by === "introMoney" ? "link-text": ""}>Intro Bonus</span>
                                                <div className="sorting-arrow">
                                                    <i className={`sorting-asc ${(by === "introMoney" && ascdsc === "asc") ? "" : "gray"}`} />
                                                    <i className={`sorting-desc ${(by === "introMoney" && ascdsc === "dsc") ? "" : "gray"}`} />
                                                </div>
                                            </th>
                                            <th className="cursor-pointer" onClick={() => this.onShortingChange("annualFee", ascdsc === "dsc" ? "asc" : "dsc")}>
                                                <span className={by === "annualFee" ? "link-text": ""}>Annual Fee</span>
                                                <div className="sorting-arrow">
                                                    <i className={`sorting-asc ${(by === "annualFee" && ascdsc === "asc") ? "" : "gray"}`} />
                                                    <i className={`sorting-desc ${(by === "annualFee" && ascdsc === "dsc") ? "" : "gray"}`} />
                                                </div>
                                            </th>
                                            <th>Rewards Rate </th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {
                                            (this.getFilteredList() || []).length ? (this.getFilteredList() || []).map((card, index) => {
                                                const { cardId, totalMoney, money, introMoney, points, introPoints, name = "", rewardInfoLong = "", totalMoneyPerYear = "", rewardDetail = [], introOfferDetail = [], rewardRate = "", link = "", features = [], annualFee = "", annualFeeDetail = [], rewardRateGeneralDetail = [] } = card || {}
                                                return(
                                                    <tr className="card-row" key={index.toString()}>
                                                        <td className="first-c">
                                                            <img
                                                                src={getCardImageURL(cardId)}
                                                                className={`card-tbl-img ga-ccApply ga-ccApply-section3-img ga-ccApply-cardId${cardId}`}
                                                                onClick={() => window.open(link, "_blank")}
                                                            /><br/>
                                                            <span className="card-nm">{name}</span>&nbsp;
                                                            { !!rewardInfoLong && <Tooltip title={rewardInfoLong} trigger={"click"}><img src={Help}/></Tooltip> }
                                                        </td>
                                                        <td>
                                                            {
                                                                (features || []).map((feature, index) => (
                                                                    <div className="feature-tab" key={index.toString()}>
                                                                        <img src={getFeaturesIcon(feature)}/>
                                                                        <span>{feature}</span>
                                                                    </div>
                                                                ))
                                                            }
                                                            {(features || []).length === 0 && <span>-</span>}
                                                        </td>
                                                        <td className="primary-bg">
                                                            <span className="blue-txt">${valueWithCommas(totalMoney || 0)}</span>
                                                            { allCardsYearProjection !== firstYear() ?
                                                                <>
                                                                    <br/> <small>{`($${valueWithCommas(totalMoneyPerYear || 0)}/year)`}</small>
                                                                </> : null
                                                            }
                                                        </td>
                                                        <td className="t-center">
                                                            <span className="blue-txt">
                                                                +${valueWithCommas(money || 0)} &nbsp;
                                                                { !!(rewardDetail || []).length &&
                                                                    <Tooltip
                                                                        title={getToolTipConstant(rewardDetail || [])}
                                                                        trigger={"click"}
                                                                    >
                                                                        <img src={Help}/>
                                                                    </Tooltip>
                                                                }
                                                            </span> <br/>
                                                            { !!points && <small>{`(${commaSeparator(points || 0)} Points)`}</small> }
                                                        </td>
                                                        <td className="t-center">
                                                            <span className="blue-txt">
                                                                +${valueWithCommas(introMoney || 0)} &nbsp;
                                                                { !!(introOfferDetail || []).length &&
                                                                    <Tooltip
                                                                        title={getToolTipConstant(introOfferDetail || [])}
                                                                        trigger={"click"}
                                                                    >
                                                                        <img src={Help}/>
                                                                    </Tooltip>
                                                                }
                                                            </span> <br/>
                                                            { !!introPoints && <small>{`(${commaSeparator(introPoints || 0)} Points)`}</small> }
                                                        </td>
                                                        <td className="t-center">
                                                            <span className="blue-txt">
                                                                -${commaSeparator(toFixedToDecimal(annualFee, 2))} &nbsp;
                                                                { !!(annualFeeDetail || []).length &&
                                                                    <Tooltip
                                                                        title={getToolTipConstant(annualFeeDetail || [])}
                                                                        trigger={"click"}
                                                                    >
                                                                        <img src={Help}/>
                                                                    </Tooltip>
                                                                }
                                                            </span>
                                                        </td>
                                                        <td className="t-center">
                                                            <span>
                                                                {rewardRate} &nbsp;
                                                                { !!(rewardRateGeneralDetail || []).length &&
                                                                    <Tooltip
                                                                        title={getToolTipConstant(rewardRateGeneralDetail || [])}
                                                                        trigger={"click"}
                                                                    >
                                                                        <img src={Help} className="cursor-pointer"/>
                                                                    </Tooltip>
                                                                }
                                                            </span>
                                                        </td>
                                                        <td className="">
                                                            <div className="d-grid">
                                                                <button
                                                                    className={`btn luci-btn primary-btn shadow-btn font-weight-bold pt-3 pb-3 mb-3 ga-ccApply ga-ccApply-section3 ga-ccApply-cardId${cardId}`}
                                                                    type="submit"
                                                                    onClick={() => window.open(link, "_blank")}
                                                                >
                                                                    Apply
                                                                </button>
                                                                <button
                                                                    className="btn luci-btn dark-btn shadow-none font-weight-bold pt-3 pb-3 mb-3"
                                                                    type="submit"
                                                                    data-toggle="modal"
                                                                    data-target="#exampleModal-a"
                                                                    onClick={() => onSelectCard(card)}
                                                                >
                                                                    Details
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) :
                                                <tr className="card-row">
                                                    <td colSpan={8}>No cards found!</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            { this.getResponsiveCardTable() }

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    state,
});

const mapDispatchToProps = dispatch => {
    return {
        onSetYearProjection: (data) => {
            dispatch(setResultSetting(data))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AllCardsView);